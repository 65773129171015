import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { SessionProvider } from './Providers/SessionProviders';

import './i18n/i18n';
import './interceptors';

// css
import './styles.css';
// fonts
import '@fontsource/barlow/300.css';
import '@fontsource/barlow/400.css';
import '@fontsource/barlow/500.css';
import '@fontsource/barlow/600.css';
// import '@fontsource/barlow/700.css';
// import '@fontsource/barlow/800.css';

const root = createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <SessionProvider>
      <App />
    </SessionProvider>
  </BrowserRouter>,
);
