import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import hn from './hn.json';

i18n.use(LanguageDetector).init({
  interpolation: {
    escapeValue: false,
  },
  resources: { en, hn },
  fallbackLng: 'en',
  react: {
    useSuspense: false,
  },
});

export const translate = (keys) => {
  return (value, options = {}) => {
    return i18n.t(value, { ns: [...keys], ...options }) || value;
  };
};

export default i18n;
