import { notification } from 'antd';
import { APP_ENV, DEFAULT_ENVIRONMENT, TOKEN_KEY } from './constant';

export const removeAuthToken = () => {
  const key = `${getCompanySlugFromURL(window.location)}-${TOKEN_KEY}`;
  localStorage.removeItem(key);
};

export const setAuthToken = (value) => {
  const key = `${getCompanySlugFromURL(window.location)}-${TOKEN_KEY}`;
  return localStorage.setItem(key, value);
};

export const getAuthToken = () => {
  const key = `${getCompanySlugFromURL(window.location)}-${TOKEN_KEY}`;
  return localStorage.getItem(key) || undefined;
};

export const randomString = () => {
  return Math.random().toString(26).substr(3, 8);
};

export const getShortName = (name) => {
  if (!name) return '';
  const [fName, lName] = name.split(' ');
  return `${fName[0]}${lName?.length > 0 ? lName[0] : ''}`;
};

/** Function to generate unique color from a given string */

const hRange = [0, 360];
const sRange = [50, 75];
const lRange = [25, 60];

const getHashOfString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
};

const HSLtoString = (hsl) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const stringToColor = (string) => {
  return HSLtoString(generateHSL(string));
};

export const dynamicSort = (property) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const range = (size, startAt = 0) => {
  return [...Array(size).keys()].map((i) => i + startAt);
};

export const openNotification = (
  message,
  description = null,
  type = 'success',
  placement = 'topRight',
) => {
  notification[type]({
    message,
    description,
    placement,
  });
};

export const getCompanyNameFromURL = (url) => {
  // Create a URL object
  const parsedUrl = new URL(url);

  // Get the pathname (e.g., '/opportune/login/asdasd')
  const pathname = parsedUrl.pathname;

  // Split the pathname by '/' and return the second segment
  const segments = pathname.split('/');

  // Check if the second segment exists (index 1 because index 0 is empty from split)
  if (segments.length > 1) {
    return segments[1]; // This will be the dynamic part ('opportune' in this case)
  }
  return null; // Return null if no segment is found
};

export const generateLinkText = (value) => {
  const slug = getCompanyNameFromURL(window.location);
  if ([DEFAULT_ENVIRONMENT.OPPORTUNE].includes(APP_ENV)) {
    return `/${slug}${value}`;
  }
  return value;
};

export const getCompanySlugFromURL = () => {
  if ([DEFAULT_ENVIRONMENT.OPPORTUNE].includes(APP_ENV)) {
    const slug = getCompanyNameFromURL(window.location);
    return slug;
  }
  return APP_ENV === DEFAULT_ENVIRONMENT.DEV
    ? 'localhost'
    : window.location.hostname.split('.nasch.io')[0];
};
