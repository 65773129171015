export const colorPalette = {
  grey: '#f5f5f5',
  white: '#fff',
  primary: {
    100: '#e0ebfb',
    200: '#bfd7f8',
    300: '#65b1ff',
    400: '#33b1ff',
    500: '#004b98',
    600: '#197ee5',
    700: '#004b98',
  },
  warning: {
    100: '#f8f1c5',
    500: '#FFAC33',
  },
  danger: '#F8727D',
};

const theme = {
  token: {
    // colorBgContainer: colorPalette.grey,
    colorPrimary: colorPalette.primary['700'],
    colorWarning: colorPalette.warning['500'],
    colorTextBase: '#0c0c0c',
    fontFamily: 'Barlow',
    borderRadius: 4,
    fontSize: 14,
    color: { ...colorPalette },
    colorLink: colorPalette.primary['700'],
    Button: {
      primaryShadow: 'none',
    },
  },
};

export default theme;
