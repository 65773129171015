import axios from 'axios';
import { generateLinkText, getAuthToken, removeAuthToken } from './utils';

axios.interceptors.request.use((config) => {
  const token = getAuthToken() || null;

  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (error) => {
    const { response } = error;
    if (response?.status === 401) {
      removeAuthToken();
      window.location = generateLinkText('/unauthorized');
    }
    return Promise.reject(error?.response || error);
  },
);
