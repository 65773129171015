import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

import { translate } from '../i18n/i18n';

const t = translate(['common']);

const StyledSpin = styled(Spin)`
  margin: 0;
  position: absolute;
  top: 48%;
  left: 48%;
`;

const FullPageLoader = () => {
  return <StyledSpin tip={t('loading')} size="large" />;
};

export default FullPageLoader;
